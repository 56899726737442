import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, message, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  ACTIVE_TABS,
  PROJECT_ENV,
  ROUTES,
  USERS_ROLE
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import EmailWrapper from '../email';
import InviteTeamMembersList from '../members/components/InviteTeamMembersList';
import SmsWrapper from '../sms';
import AddProjectEnvironmentModal from './components/AddProjectEnvironmentModal';
import EditProject from './components/EditProject';
import DeleteENVModal from './DeleteENVModal';
import {
  ADD_PROJECT_ENVIRONMENT,
  DELETE_PROJECT_ENVIRONMENT
} from './graphql/Mutations';
import { PROJECT_ENVIRONMENT } from './graphql/Queries';

const { Option } = Select;
const ProjectsDashboard = () => {
  const [form] = Form.useForm();
  const { dispatch, state } = useContext(AppContext);
  const { projectId, key } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const [isAddEnvironmentModalOpen, setAddEnvironmentModalOpen] = useState(
    false
  );
  const [projectEnvironment, setProjectEnvironment] = useState([]);
  const [projectENVDeleteModal, setProjectENVDeleteModal] = useState(false);
  const [projectENVDeleteLoading, setProjectENVDeleteLoading] = useState(false);
  const [projectENVDeleteData, setProjectENVDeleteData] = useState();
  const [value, setValue] = useState();
  const [loadings, setLoadings] = useState(true);
  const [envValue, setEnvValue] = useState();
  const { text } = formValidatorRules;
  const [activeKey, setActiveKey] = useState(
    // eslint-disable-next-line no-undef
    localStorage.getItem(ACTIVE_TABS) || ROUTES?.ANALYTICS
  );

  const showProjectEnvironmentModal = () => {
    setAddEnvironmentModalOpen(true);
  };

  const handleChange = (val) => {
    setValue(val);
    dispatch({
      type: 'SET_PROJECT_ENV_ID',
      data: val
    });
  };

  const [executeProjectEnvironment] = useLazyQuery(PROJECT_ENVIRONMENT, {
    onCompleted: (response) => {
      setLoadings(false);
      setProjectEnvironment([...response?.projectEnvironment?.environments]);
      response?.projectEnvironment?.environments?.forEach((environment) => {
        if (environment?.environment === envValue) {
          handleChange(environment?.id);
        }
      });
      if (!envValue) {
        setValue(response?.projectEnvironment?.environments?.[0]?.id);
        dispatch({
          type: 'SET_PROJECT_ENV_ID',
          data: response?.projectEnvironment?.environments?.[0]?.id
        });
        dispatch({
          type: 'CREATE_NEW_PROJECT',
          data: false
        });
      } else {
        // eslint-disable-next-line no-undef
        setValue(envValue);
        dispatch({
          type: 'SET_PROJECT_ENV_ID',
          data: envValue
        });
      }
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [addProjectEnvironment] = useMutation(ADD_PROJECT_ENVIRONMENT);

  const [deleteProjectEnvironment] = useMutation(DELETE_PROJECT_ENVIRONMENT);

  const onFinish = (values) => {
    setLoadings(true);
    addProjectEnvironment({
      variables: {
        data: {
          environment: values?.environment,
          projectId: projectId
        }
      }
    })
      .then((response) => {
        executeProjectEnvironment({
          variables: {
            projectId: projectId
          }
        });
        setEnvValue(response?.data?.createProjectEnvironment?.data?.id);
        setAddEnvironmentModalOpen(false);
      })
      .catch((error) => {
        return error;
      });
    setLoadings(false);
    setAddEnvironmentModalOpen(false);
    form?.resetFields();
  };

  const deleteProjectENV = () => {
    if (projectEnvironment?.length > 1) {
      setProjectENVDeleteLoading(true);
      deleteProjectEnvironment({
        variables: {
          id: projectENVDeleteData?.id
        }
      })
        .then(() => {
          setEnvValue();
          setValue();
          executeProjectEnvironment({
            variables: {
              projectId: projectId
            }
          });
          setProjectENVDeleteModal(false);
          setProjectENVDeleteData();
          setProjectENVDeleteLoading(false);
        })
        .catch((error) => {
          return error;
        });
      setLoadings(false);
    } else {
      message?.error('Single env’s cannot be deleted!');
    }
  };

  useEffect(() => {
    setValue();
    setLoadings(true);
    executeProjectEnvironment({
      variables: {
        projectId: projectId
      }
    });
    if (pathname !== history.location.pathname) {
      setEnvValue();
      setActiveKey(ROUTES?.ANALYTICS);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (state?.createNewProject) {
        setEnvValue();
      } else {
        // eslint-disable-next-line no-undef
        setEnvValue(localStorage.getItem(PROJECT_ENV));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const AddProjectEnvironmentFormsItems = [
    {
      title: 'Project Environment',
      name: 'environment',
      placeholder: 'Enter project environment',
      rules: [
        { required: true, message: 'Please input project environment!' },
        text
      ]
    }
  ];

  const handleKeyComponent = (keyPath) => {
    switch (keyPath) {
      case `${ROUTES?.EMAIL}`:
        return (
          <EmailWrapper
            projectId={projectId}
            setActiveKey={setActiveKey}
            activeKey={activeKey}
          />
        );
      case `${ROUTES?.SMS}`:
        return <SmsWrapper projectId={projectId} />;
      case `${ROUTES?.MEMBERS}`:
        return <InviteTeamMembersList />;
      case `${ROUTES?.EDIT_PROJECT}`:
        return <EditProject />;
      default:
        break;
    }
  };

  if (loadings) return <LoaderComponent />;

  return (
    <>
      <Portal portalId="env-dropdown">
        <Select
          virtual={false}
          className="env-select"
          // eslint-disable-next-line no-undef
          getPopupContainer={() =>
            // eslint-disable-next-line no-undef
            document?.getElementById('env-dropdown')
          }
          value={value}
          onChange={handleChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              {(projectEnvironment?.[0]?.permission === USERS_ROLE?.OWNER ||
                projectEnvironment?.[0]?.permission === USERS_ROLE?.WRITE) && (
                <span
                  className="create-new-env"
                  onClick={showProjectEnvironmentModal}
                >
                  + Create New Environment
                </span>
              )}
              <AddProjectEnvironmentModal
                form={form}
                isModalOpen={isAddEnvironmentModalOpen}
                setIsModalOpen={setAddEnvironmentModalOpen}
                formTitle="Add Project Environment"
                onFinish={onFinish}
                formItems={AddProjectEnvironmentFormsItems}
                submitButton="Add Environment"
                loadings={loadings}
              />
            </>
          )}
        >
          {projectEnvironment?.map((environment) => {
            return (
              <Option key={environment?.id} value={environment?.id}>
                <div className="d-flex justify-between align-center">
                  {environment?.environment}{' '}
                  {(projectEnvironment?.[0]?.permission === USERS_ROLE?.OWNER ||
                    projectEnvironment?.[0]?.permission ===
                      USERS_ROLE?.WRITE) && (
                    <DeleteOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setProjectENVDeleteData(environment);
                        setProjectENVDeleteModal(true);
                      }}
                    />
                  )}
                </div>
              </Option>
            );
          })}
        </Select>
        {projectENVDeleteModal && (
          <DeleteENVModal
            isModalOpen={projectENVDeleteModal}
            setIsModalOpen={setProjectENVDeleteModal}
            value={projectENVDeleteData}
            setProjectENVDeleteData={setProjectENVDeleteData}
            submitButton="Delete"
            onFinish={deleteProjectENV}
            loadings={projectENVDeleteLoading}
          />
        )}
      </Portal>
      {handleKeyComponent(`/${key}`)}
    </>
  );
};

export default ProjectsDashboard;
